<template>
	<!-- begin::page-container(#회원가입) -->
	<main class="page-container" id="sme-signup-policy">
		<div class="auth-component">
			<header class="auth-header">
				<!-- <div class="row">
          <button type="button" class="btn col-2 " :class="{ 'is-active': isHidden, 'btn-secondary':isHidden }" @click="changePolicy(true)">신규 약관</button>
          <button type="button" class="btn col-2 " :class="{ 'is-active': !isHidden, 'btn-secondary':!isHidden }" @click="changePolicy(false)">이전 약관</button>
        </div> -->
				<h2 class="title">이용약관</h2>
			</header>
			<div class="auth-body">
				<!-- 2024-08-21 수정 이용약관 -->
				<terms-of-use />

				<!-- 신규 약관 -->
				<section v-if="isHidden" class="policy-article-wrapper">
					<!-- <article class="policy-article">
						<h3 class="policy-article-header">제1조 (목적)</h3>
						<p class="policy-article-text">
							본 약관은 중소기업유통센터에서 운영하는 가치삽시다 플랫폼과 판판대로 사이트(이하 “당 사이트“라 한다)가 제공하는 지원사업 관련 서비스(이하 “서비스”라 한다)의 이용조건 및 절차, 이용자와 당 사이트의 권리, 의무,<br />
							책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.
						</p>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 2조 (용어의 정의)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">1."서비스"라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 "회원"이 이용할 수 있는 서비스를 의미합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."이용자"라 함은 당 사이트에 접속하여 본 약관 및 개인정보처리방침에 동의하고 당 사이트가 제공하는 서비스를 이용하는 회원 및 비회원을 말합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."회원"이라 함은 회원가입을 통하여 아이디(ID)와 비밀번호를 발급받는 자로서, 당 사이트와 이용계약을 체결하고 당 사이트가 제공하는 "서비스"를 이용하는 고객을 말합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.“비회원”이라 함은 “회원”에 가입하지 않고 당 사이트가 제공하는 서비스를 이용하는 자를 말합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5."아이디(ID)"라 함은 "회원"의 식별과 "서비스" 이용을 위하여 "회원"이 정하고 당 사이트가 승인하는 문자와 숫자의 조합을 의미합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">6."비밀번호"라 함은 "회원"이 부여받은 "아이디(ID)와 일치되는 "회원"임을 확인하고 자신의 비밀보호를 위해 "회원" 이 정한 문자 또는 숫자의 조합을 의미합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">7."게시물"이라 함은 “이용자”가 "서비스"를 이용함에 있어 "서비스상"에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 3조 (약관의 게시와 개정)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.당 사이트는 이 약관의 내용을 “이용자”가 쉽게 알 수 있도록 서비스 초기화면에 게시합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.당 사이트는 "약관의규제에관한법률", "정보통신망이용촉진및정보보호등에관한법률(이하 "정보통신망법")" 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">
									3.당 사이트가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지합니다.<br />
									다만, 이용자에게 불리한 약관의 개정의 경우에는 공지 외에 일정기간 서비스 내 전자우편, 로그인시 동의창, SMS 문자, 전화 등을 통해 따로 통지하도록 합니다.
								</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">
									4.당 사이트가 전항에 따라 개정약관을 공지 또는 통지하면서 이용자에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 이용자가 명시적으로 거부의 의사표시를<br />
									하지 아니한 경우 개정약관에 동의한 것으로 봅니다.
								</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5.이용자가 개정약관의 적용에 동의하지 않는 경우 당 사이트는 개정약관의 내용을 적용할 수 없으며, 이용자는 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 당 사이트는 이용계약을 해지할 수 있습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 4 조 (이용계약 체결)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 당 사이트가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.당 사이트는 "가입신청자"의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.</p>
								<p class="policy-article-text">　①가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 당 사이트의 회원 재가입 승낙을 얻은 경우에는 예외로 함.</p>
								<p class="policy-article-text">　②실명이 아니거나 타인의 명의를 이용한 경우</p>
								<p class="policy-article-text">　③허위의 정보를 기재하거나, 당 사이트가 제시하는 내용을 기재하지 않은 경우</p>
								<p class="policy-article-text">　④이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3.제1항에 따른 신청에 있어 당 사이트는 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.당 사이트는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5.제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 당 사이트는 원칙적으로 이를 가입신청자에게 알리도록 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">6.이용계약의 성립 시기는 당 사이트가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">7.당 사이트는 "회원"에 대해 정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 5 조 (회원정보의 변경)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."회원"은 회원정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 아이디(ID), 고유번호 등은 수정이 불가능합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.회원 아이디(ID)는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경 하고자 하는 경우에는 재가입해야 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 등 기타 방법으로 당 사이트에 대하여 그 변경사항을 알려야 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.제2항의 변경사항을 당 사이트에 알리지 않아 발생한 불이익에 대하여 당 사이트는 책임지지 않습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 6 조 (개인정보보호 의무)</h3>
						<p class="policy-article-text">
							당 사이트는 "정보통신망법" 등 관계 법령이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 당 사이트의 개인정보처리방침이 적용됩니다.<br />
							다만, 당 사이트 이외의 링크된 사이트에서는 당 사이트의 개인정보처리방침이 적용되지 않습니다.
						</p>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 7 조 ("회원"의 "아이디(ID)" 및 "비밀번호"의 관리에 대한 의무)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."회원"의 "아이디(ID)"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.당 사이트는 "회원"의 "아이디(ID)"가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 당 사이트 및 당 사이트의 운영자로 오인한 우려가 있는 경우, 해당 "아이디(ID)"의 이용을 제한할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."회원"은 "아이디(ID)" 및 "비밀번호"가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 당 사이트에 통지하고 당 사이트의 안내에 따라야 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.제3항의 경우에 해당 "회원"이 당 사이트에 그 사실을 통지하지 않거나, 통지한 경우에도 당 사이트의 안내에 따르지 않아 발생한 불이익에 대하여 당 사이트가 책임지지 않습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 8 조 ("회원"에 대한 통지)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.당 사이트가 "회원"에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편이나, 전화, SMS 문자 등으로 할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.당 사이트는 "회원" 전체에 대한 통지의 경우 7일 이상 당 사이트의 게시판 등에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 9 조 ("가치삽시다"의 의무)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.당 사이트는 관련 법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.당 사이트는 "이용자"가 안전하게 "서비스"를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3.당 사이트는 서비스 이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">
									4.당 사이트는 서비스 이용과 관련하여 "이용자"로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다.<br />
									"이용자"가 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 "이용자"에게 처리과정 및 결과를 전달합니다.
								</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 10 조 ("회원"의 의무)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."회원"은 다음 행위를 하여서는 안 됩니다.</p>
								<p class="policy-article-text">　①신청 또는 변경 시 허위내용의 등록</p>
								<p class="policy-article-text">　②타인의 정보도용</p>
								<p class="policy-article-text">　③당 사이트가 게시한 정보의 변경</p>
								<p class="policy-article-text">　④당 사이트가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</p>
								<p class="policy-article-text">　⑤당 사이트와 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
								<p class="policy-article-text">　⑥당 사이트 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</p>
								<p class="policy-article-text">　⑦외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 "서비스"에 공개 또는 게시하는 행위</p>
								<p class="policy-article-text">　⑧당 사이트의 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위</p>
								<p class="policy-article-text">　⑨기타 불법적이거나 부당한 행위</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."회원"은 관계법, 이 약관의 규정, 이용안내 및 "서비스"와 관련하여 공지한 주의사항, 당 사이트가 통지하는 사항 등을 준수하여야 하며, 기타 당 사이트의 업무에 방해되는 행위를 하여서는 안 됩니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 11 조 ("서비스"의 제공 등)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.당 사이트는 회원에게 아래와 같은 서비스를 제공합니다.</p>
								<p class="policy-article-text">- 가치삽시다 서비스</p>
								<p class="policy-article-text">　① 교육·정보형 콘텐츠 서비스</p>
								<p class="policy-article-text">　② 소상공인 전용 인프라 예약 서비스(라이브스튜디오 및 소담스퀘어)</p>
								<p class="policy-article-text">　③ 중앙부처(중소벤처기업부) 및 지자체 운영 온라인 판로지원 사업안내</p>
								<p class="policy-article-text">　④ 소상공인 상품 홍보관 서비스 (우수 소상공인 상품관, 지자체 지역경제관, 동행축제 상품관)</p>
								<p class="policy-article-text">　⑤ 당 사이트 “회원”의 콘텐츠 이용현황, 지원사업 참여 현황 확인할 수 있는 마이페이지 기능</p>
								<p class="policy-article-text">　⑥ 그 外 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 당 사이트가 "회원"에게 제공하는 일체의 서비스</p>
								<p class="policy-article-text">- 판판대로 서비스</p>
								<p class="policy-article-text">　① 상품소개</p>
								<p class="policy-article-text">　② 사업신청</p>
								<p class="policy-article-text">　③ 게시판형 서비스</p>
								<p class="policy-article-text">　④ 기타 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 "회원"에게 제공하는 일체의 서비스</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.당 사이트는 "서비스"를 이용가능 일시 및 시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."서비스"는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">
									4.당 사이트는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "서비스"의 제공을 일시적으로 중단할 수 있습니다. 이 경우 당 사이트는 "회원"에게 통지합니다.<br />
									다만, 당 사이트가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
								</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5.당 사이트는 서비스의 제공에 필요한 경우 시스템 점검, 증설 및 교체 등을 위해 정기점검을 실시할 수 있으며, 작업으로 인해 서비스를 일시중단할 수 있습니다. 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">6.당 사이트는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">7."서비스"의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 12 조 (정보의 제공 및 광고의 게재)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">
									1.당 사이트는 “이용자"가 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편, SMS 문자 등의 방법으로 제공할 수 있습니다.<br />
									다만, "회원"은 관련 법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편 및 SMS문자에 대해서 수신 거절을 할 수 있습니다.
								</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.제1항의 정보를 전송하려고 하는 경우에는 “이용자"의 사전 동의를 받아서 전송합니다. 다만, "회원"의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 13 조 ("게시물"의 저작권)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.당 사이트가 게시한 본 홈페이지의 모든 콘텐츠에 대한 저작권은 당 사이트에 있습니다. 다만, 게시물의 원저작자가 별도로 있는 경우 그 출처를 명시하며 해당 게시물의 저작권은 원저작자에게 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."회원"이 "서비스" 내에 게시한 "게시물"의 저작권은 해당 게시물의 저작자에게 귀속됩니다. 다만, 회원은 당 사이트에 무료로 이용할 수 있는 권리를 허락한 것으로 봅니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3.당 사이트는 저작권법 규정을 준수하며, "회원"은 언제든지 고객센터 또는 "서비스" 내 관리기능을 통해 해당 게시물에 대해 삭제, 비공개 등의 조치를 취할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.당 사이트 소유의 콘텐츠에 대하여 제3자가 허락 없이 다른 홈페이지에 사용 또는 인용하는 것을 금지합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5.“이용자”의 게시물이 타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 해당 “이용자”가 부담하여야 합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 14 조 ("게시물의 관리")</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.“이용자”의 "게시물"이 "정보통신망법" 및 "저작권법"등 관련 법에 위반되는 내용을 포함하는 경우, 권리자는 관련 법이 정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수 있으며, 당 사이트는 관련 법에 따라 조치를 취하여야 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.당 사이트는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 당 사이트정책 및 관련 법에 위반되는 경우에는 관련 법에 따라 해당 "게시물"에 대해 임시조치(사전통지 없이 삭제·이동·등록거부 등)를 취할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3.당 사이트는 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는 권리를 보유하며, 다음의 경우 사전 통지 없이 삭제할 수 있습니다.</p>
								<p class="policy-article-text">　①본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우</p>
								<p class="policy-article-text">　②다른 “회원” 또는 제 3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우</p>
								<p class="policy-article-text">　③공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우</p>
								<p class="policy-article-text">　④범죄적 행위에 결부된다고 객관적으로 인정되는 내용일 경우</p>
								<p class="policy-article-text">　⑤제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</p>
								<p class="policy-article-text">　⑥불법복제 또는 해킹을 조장하는 내용인 경우</p>
								<p class="policy-article-text">　⑦영리를 목적으로 하는 광고일 경우</p>
								<p class="policy-article-text">　⑧당 사이트에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우 그 외 기타 관계 법령에 위배되는 경우 등 이용자의 게시물이 타인의 저작권을 침해함으로써 발생하는 민·형사상의 책임은 전적으로 이용자가 부담하여야 합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 15 조 (회원 탈퇴 등)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.“회원”은 회사에 언제든지 당 사이트의 [회원탈퇴] 및 고객센터를 통하여 서비스 탈퇴를 요청할 수 있으며, 당 사이트는 관련 법 등이 정하는 바에 따라 이를 처리하여야 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."회원"이 탈퇴를 요청 한 경우, 관련 법 및 개인정보처리방침에 따라 개인정보를 파기합니다. 법령에 의해 수집·이용되는 회원 정보의 경우 개인정보처리방침에 명시한 기간 동안 보존합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">
									3."회원"이 탈퇴를 요청하는 경우, “가치삽시다”는 "회원"이 작성한 "게시물"은 유지되나, 작성한 게시물에 대해 “아이디(ID)”는 “***”형태와 같이 마스킹 처리되고 게시물의 삭제를 원하실 경우에는 고객센터 등을 통해 별도로 요청해주시기 바라며,<br />
									“판판대로”는 "회원"이 작성한 "게시물" 일체가 삭제됩니다. 다만, 타인에 의해 담기, 스크랩 등이 되어 재게시되거나, 공용게시판에 등록된 "게시물" 등은 삭제되지 않으니 사전에 삭제 신청 후 탈퇴하시기 바랍니다.
								</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4."회원“이 탈퇴 전 이용하던 중 종결되지 아니한 업무가 존재하는 경우(스튜디오 예약내역 등) 해당 내역은 삭제되며, 이용이 불가능합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5.탈퇴 후 재가입하는 회원은 최종 이용할 때 사용한 아이디(ID)는 사용할 수 없으며, 새로운 아이디(ID)를 사용하는 것을 원칙으로 합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 16 조 (이용제한 등)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.당 사이트는 “이용자"가 이 약관의 의무를 위반하거나 "서비스"의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 "서비스" 이용을 단계적으로 제한할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">
									2.당 사이트는 전항에도 불구하고, "주민등록법"을 위반한 명의도용 및 결제도용, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해,<br />
									"정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속 권한 초과행위 등과 같이 관련 법을 위반한 경우에는 즉시 휴면계정 처리할 수 있습니다.
								</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3.당 사이트는 "회원"이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는 경우에는 반드시 “이용자"에게 통지합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5.“이용자"는 본 조에 따른 이용제한 등에 대해 당 사이트가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 인정되는 경우 즉시 "서비스"의 이용이 재개됩니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 17 조 (책임제한)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.당 사이트는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.당 사이트는 서비스 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대해서는 책임이 면제됩니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3.당 사이트는 “이용자"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여는 책임을 지지 않습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.당 사이트는 “이용자"가 "서비스"와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5.당 사이트는 "회원" 간 또는 "회원"과 제3자 등 이용자 상호간에 "서비스"를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">6.당 사이트는 무료로 제공되는 서비스 이용과 관련하여 관련 법에 특별한 규정이 없는 한 책임을 지지 않습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 18 조 (준거법 및 재판관할)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.본 약관에 명시되지 않은 사항은 정보통신망법 등 대한민국의 관계 법령과 상관례에 따릅니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.본 서비스 이용과 관련하여 발생한 분쟁에 대해 소송이 제기될 경우 중소기업유통센터 소재지를 관할하는 법원을 관할 법원으로 합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">부칙(시행일)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">본 약관은 2024. 4. 1.부터 시행됩니다. 2024년. 4월. 1일.부터 시행되던 기존의 약관은 본 약관으로 대체됩니다.</p>
							</li>
						</ul>
					</article> -->
				</section>

				<!-- 이전 약관 -->
				<section v-if="!isHidden" class="policy-article-wrapper">
					<!-- <article class="policy-article">
						<h3 class="policy-article-header">제1조 (목적)</h3>
						<p class="policy-article-text">
							본 약관은 중소기업유통센터에서 운영하는 가치삽시다 신규포털 홈페이지(이하 "가치삽시다"라 한다)가 제공하는 모든 서비스(이하 "서비스")의 이용조건 및 절차, 이용자와 당 사이트의 권리, 의무,<br />
							책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.
						</p>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 2조 (용어의 정의)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">1."서비스"라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 "회원"이 이용할 수 있는 가치삽시다의 서비스를 의미합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."이용자"라 함은 당 사이트에 접속하여 이 약관에 따라 당 사이트가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."회원"이라 함은 가치삽시다의 "서비스"에 접속하여 이 약관에 따라 "가치삽시다"와 이용계약을 체결하고 "가치삽시다"가 제공하는 "서비스"를 이용하는 고객을 말합니다. 단, "가치삽시다 마켓"의 “회원”과는 별도로 운영·관리됩니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4."아이디(ID)"라 함은 "회원"의 식별과 "서비스" 이용을 위하여 "회원"이 정하고 "가치삽시다"가 승인하는 문자와 숫자의 조합을 의미합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5."비밀번호"라 함은 "회원"이 부여 받은 "아이디(ID)와 일치되는 "회원"임을 확인하고 자신의 비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을 의미합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">6."게시물"이라 함은 "회원"이 "서비스"를 이용함에 있어 "서비스상"에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 3조 (약관의 게시와 개정)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."가치삽시다"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다. 단, "가치삽시다 마켓"의 이용약관에 해당하는 내용은 별도로 가치삽시다 마켓 초기화면에 게시합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."가치삽시다"는 "약관의규제에관한법률", "정보통신망이용촉진및정보보호등에관한법률(이하 "정보통신망법")" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."가치삽시다"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 공지 외에 일정기간 서비스 내 전자우편, 로그인시 동의창 등의 전자적 수단을 통해 따로 통지하도록 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.“가치삽시다”가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5.회원이 개정약관의 적용에 동의하지 않는 경우 “가치삽시다”는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 “가치삽시다”는 이용계약을 해지할 수 있습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 4 조 (이용계약 체결)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 "가치삽시다"가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."가치삽시다"는 "가입신청자"의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, "가치삽시다"는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.</p>
								<p class="policy-article-text">　①가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 "가치삽시다"의 회원 재가입 승낙을 얻은 경우에는 예외로 함.</p>
								<p class="policy-article-text">　②실명이 아니거나 타인의 명의를 이용한 경우</p>
								<p class="policy-article-text">　③허위의 정보를 기재하거나, "가치삽시다"가 제시하는 내용을 기재하지 않은 경우</p>
								<p class="policy-article-text">　④이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3.제1항에 따른 신청에 있어 "가치삽시다"는 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4."가치삽시다"는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5.제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, "가치삽시다 "는 원칙적으로 이를 가입신청자에게 알리도록 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">6.이용계약의 성립 시기는 "가치삽시다"가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">7."가치삽시다 "는 "회원"에 대해 정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 5 조 (회원정보의 변경)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."회원"은 회원정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 아이디(ID), 고유번호 등은 수정이 불가능합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.회원 아이디(ID)는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경 하고자 하는 경우에는 재가입해야 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 등 기타 방법으로 "가치삽시다"에 대하여 그 변경사항을 알려야 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.제2항의 변경사항을 "가치삽시다"에 알리지 않아 발생한 불이익에 대하여 "가치삽시다"는 책임지지 않습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 6 조 (개인정보보호 의무)</h3>
						<p class="policy-article-text">"가치삽시다"는 "정보통신망법" 등 관계 법령이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 "가치삽시다"의 개인정보처리방침이 적용됩니다. 다만, "가치삽시다"의 공식 사이트 이외의 링크된 사이트에서는 "가치삽시다"의 개인정보처리방침이 적용되지 않습니다.</p>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 7 조 ("회원"의 "아이디(ID)" 및 "비밀번호"의 관리에 대한 의무)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."회원"의 "아이디(ID)"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."가치삽시다"는 "회원"의 "아이디(ID)"가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 "가치삽시다" 및 "가치삽시다"의 운영자로 오인한 우려가 있는 경우, 해당 "아이디(ID)"의 이용을 제한할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."회원"은 "아이디(ID)" 및 "비밀번호"가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 "가치삽시다"에 통지하고 "가치삽시다"의 안내에 따라야 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.제3항의 경우에 해당 "회원"이 "가치삽시다"에 그 사실을 통지하지 않거나, 통지한 경우에도 "가치삽시다"의 안내에 따르지 않아 발생한 불이익에 대하여 "가치삽시다"는 책임지지 않습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 8 조 ("회원"에 대한 통지)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."가치삽시다"가 "회원"에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소 등으로 할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."가치삽시다"는 "회원" 전체에 대한 통지의 경우 7일 이상 "가치삽시다"의 게시판 등에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 9 조 ("가치삽시다"의 의무)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."가치삽시다"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."가치삽시다"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."가치삽시다"는 서비스이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4."가치삽시다"는 서비스이용과 관련하여 “회원”으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. "회원"이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 "회원"에게 처리과정 및 결과를 전달합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 10 조 ("회원"의 의무)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."회원"은 다음 행위를 하여서는 안 됩니다.</p>
								<p class="policy-article-text">　①신청 또는 변경 시 허위내용의 등록</p>
								<p class="policy-article-text">　②타인의 정보도용</p>
								<p class="policy-article-text">　③"가치삽시다"가 게시한 정보의 변경</p>
								<p class="policy-article-text">　④"가치삽시다"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</p>
								<p class="policy-article-text">　⑤"가치삽시다"와 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
								<p class="policy-article-text">　⑥"가치삽시다" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</p>
								<p class="policy-article-text">　⑦외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 "서비스"에 공개 또는 게시하는 행위</p>
								<p class="policy-article-text">　⑧“가치삽시다”의 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위</p>
								<p class="policy-article-text">　⑨기타 불법적이거나 부당한 행위</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."회원"은 관계법, 이 약관의 규정, 이용안내 및 "서비스"와 관련하여 공지한 주의사항, "가치삽시다"가 통지하는 사항 등을 준수하여야 하며, 기타 "가치삽시다"의 업무에 방해되는 행위를 하여서는 안 됩니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 11 조 ("서비스"의 제공 등)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.“가치삽시다”는 회원에게 아래와 같은 서비스를 제공합니다.</p>
								<p class="policy-article-text">　① 교육·정보형 콘텐츠 서비스</p>
								<p class="policy-article-text">　② 스튜디오 및 소담스퀘어 예약 서비스</p>
								<p class="policy-article-text">　③ 중앙부처 등 온라인 판로지원 사업안내</p>
								<p class="policy-article-text">　④ 소상공인 상품 판매 및 홍보를 위한 가치삽시다 마켓 서비스</p>
								<p class="policy-article-text">　⑤ 그 外 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 “가치삽시다”가 "회원"에게 제공하는 일체의 서비스</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.“가치삽시다”는 "서비스"를 이용가능 일시 및 시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."서비스"는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4."가치삽시다"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "서비스"의 제공을 일시적으로 중단할 수 있습니다. 이 경우 "가치삽시다"는 "회원"에게 통지합니다. 다만, "가치삽시다"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5."가치삽시다"는 서비스의 제공에 필요한 경우 시스템 점검, 증설 및 교체 등을 위해 정기점검을 실시할 수 있으며, 작업으로 인해 서비스를 일시중단할 수 있습니다. 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">6."가치삽시다"는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">7."서비스"의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 12 조 (정보의 제공 및 광고의 게재)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."가치삽시다"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편, SMS문자 등의 방법으로 "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편 및 SMS문자에 대해서 수신 거절을 할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.제 1항의 정보를 전송하려고 하는 경우에는 "회원"의 사전 동의를 받아서 전송합니다. 다만, "회원"의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 13 조 ("게시물"의 저작권)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.당 사이트가 게시한 본 홈페이지의 모든 콘텐트에 대한 저작권은 당 사이트에 있습니다. 다만, 게시물의 원저작자가 별도로 있는 경우 그 출처를 명시하며 해당 게시물의 저작권은 원저작자에게 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."회원"이 "서비스" 내에 게시한 "게시물"의 저작권은 해당 게시물의 저작자에게 귀속됩니다. 다만, 회원은 당 사이트에 무료로 이용할 수 있는 권리를 허락한 것으로 봅니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3.“가치삽시다”는 저작권법 규정을 준수하며, "회원"은 언제든지 고객센터 또는 "서비스" 내 관리기능을 통해 해당 게시물에 대해 삭제, 비공개 등의 조치를 취할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.당 사이트 소유의 콘텐츠에 대하여 제3자가 허락 없이 다른 홈페이지에 사용 또는 인용하는 것을 금지합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5.“회원” 또는 “이용자”의 게시물이 타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 귀하가 부담하여야 합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 14 조 ("게시물의 관리")</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."회원"의 "게시물"이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수 있으며, "가치삽시다"는 관련법에 따라 조치를 취하여야 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."가치삽시다"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 가치삽시다 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 "게시물"에 대해 임시조치(사전통지 없이 삭제·이동·등록거부 등)를 취할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3.“가치삽시다”에 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는 권리를 보유하며, 다음의 경우 사전 통지 없이 삭제할 수 있습니다.</p>
								<p class="policy-article-text">　①본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우</p>
								<p class="policy-article-text">　②다른 “회원” 또는 제 3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우</p>
								<p class="policy-article-text">　③공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우</p>
								<p class="policy-article-text">　④범죄적 행위에 결부된다고 객관적으로 인정되는 내용일 경우</p>
								<p class="policy-article-text">　⑤제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</p>
								<p class="policy-article-text">　⑥불법복제 또는 해킹을 조장하는 내용인 경우</p>
								<p class="policy-article-text">　⑦영리를 목적으로 하는 광고일 경우</p>
								<p class="policy-article-text">
									　⑧당 사이트에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우 그 외 기타 관계 법령에 위배되는 경우 등 이용자의 게시물이 타인의 저작권을 침해함으로써 발생하는<br />
									　　민·형사상의 책임은 전적으로 이용자가 부담하여야 합니다.
								</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 15 조 (회원 탈퇴 등)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1.“회원”은 회사에 언제든지 포털 사이트의 [회원탈퇴] 및 고객센터를 통하여 서비스 탈퇴를 요청할 수 있으며, "가치삽시다"는 관련법 등이 정하는 바에 따라 이를 처리하여야 합니다. 단, 마켓 입점신청을 진행한 회원의 경우, 고객센터 및 마켓 담당자를 통한 확인 후 탈퇴가 가능합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."회원"이 탈퇴를 요청 한 경우, 관련법 및 개인정보처리방침에 따라 개인정보를 파기합니다. 법령에 의해 수집·이용되는 회원 정보의 경우 개인정보처리방침에 명시한 기간 동안 보존합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."회원"이 탈퇴를 요청하는 경우, "회원"이 작성한 "게시물"은 유지되나, 작성한 게시물에 대해 “아이디(ID)”는 “***”형태와 같이 마스킹 처리됩니다. 다만, 게시물의 삭제를 원하실 경우에는 고객센터 등을 통해 별도로 요청해주시기 바랍니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4. "회원“이 탈퇴 전 이용하던 중 종결되지 아니한 업무가 존재하는 경우(스튜디오 예약내역 등) 해당 내역은 삭제 되며, 이용이 불가능 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5. 탈퇴 후 재가입하는 회원은 최종 이용할 때 사용한 아이디(ID)는 사용할 수 없으며, 새로운 아이디(ID)를 사용하는 것을 원칙으로 합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 16 조 (이용제한 등)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."가치삽시다"는 "회원"이 이 약관의 의무를 위반하거나 "서비스"의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 "서비스" 이용을 단계적으로 제한할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."가치삽시다"는 전항에도 불구하고, "주민등록법"을 위반한 명의도용 및 결제도용, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 휴면계정 처리할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."가치삽시다"는 "회원"이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4.본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는 경우에는 반드시 "회원"에게 통지합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5."회원"은 본 조에 따른 이용제한 등에 대해 "가치삽시다"가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 "가치삽시다"가 인정하는 경우 즉시 "서비스"의 이용을 재개합니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 17 조 (책임제한)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."가치삽시다"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2."가치삽시다"는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여는 책임을 지지 않습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">3."가치삽시다"는 "회원"이 "서비스"와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">4."가치삽시다"는 "회원" 간 또는 "회원"과 제3자 상호간에 "서비스"를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">5."가치삽시다"는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</p>
							</li>
						</ul>
					</article>

					<article class="policy-article">
						<h3 class="policy-article-header">제 18 조 (준거법 및 재판관할)</h3>
						<ul class="policy-article-list">
							<li class="policy-article-item">
								<p class="policy-article-text">1."가치삽시다"와 "회원" 간 제기된 소송은 대한민국법을 준거법으로 합니다.</p>
							</li>
							<li class="policy-article-item">
								<p class="policy-article-text">2.본 서비스 이용과 관련하여 발생한 분쟁에 대해 소송이 제기될 경우 중소기업유통센터 소재지를 관할하는 법원을 관할 법원으로 합니다.</p>
							</li>
						</ul>
					</article> -->
				</section>
			</div>
		</div>
	</main>
	<!-- end::page-container(#회원가입) -->
</template>

<script>
import TermsOfUse from '@/views/pages/user/TermsOfUse';

export default {
	name: 'Policy',
	components: { TermsOfUse },
	data: () => ({
		isHidden: true,
	}),
	methods: {
		changePolicy(val) {
			this.isHidden = val;
		},
	},
};
</script>
